@charset "UTF-8";
body {
  font-size: 18px;
  line-height: 1.5;
}
h6 {
  font-size: 18px;
}
h5 {
  font-size: 18px;
  line-height: 1.5;
}
h4 {
  font-size: 20.25px;
  line-height: 1.25;
  line-height: 1.3;
  font-variant: small-caps;
}
h3 {
  font-size: 22.78125px;
  line-height: 1.1666666667;
  line-height: 1.2;
  font-variant: small-caps;
}
h2 {
  font-size: 25.62890625px;
  line-height: 1.125;
  font-variant: small-caps;
}
h1 {
  font-size: 28.8325195313px;
  line-height: 1;
}
blockquote {
  color: #3e3e3e;
  position: relative;
  padding: 0 1em;
  line-height: 1;
  margin: 0;
  font-style: italic;
}
blockquote p {
  margin: 0;
}
blockquote:before {
  float: left;
  width: 0.5em;
  height: 0.5em;
  position: relative;
  top: -0.2em;
  content: "“";
  font-size: 2em;
  opacity: 0.5em;
}
blockquote cite {
  margin-top: 0.5em;
  font-style: normal;
  text-align: right;
  display: block;
}
blockquote cite:before {
  content: "—";
}
dl {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.8em;
}
dl dd, dl dt {
  margin: 0;
  padding: 0em 0;
}
dl dt {
  flex: 1 0 50%;
  padding-right: 1em;
  border-right: 1px solid black;
  text-align: right;
}
dl dt:first-line {
  font-weight: bold;
}
dl dd {
  position: relative;
  left: -1px;
  border-left: 1px solid black;
  flex: 1 0 50%;
  padding-left: 1em;
  text-align: left;
}
hr {
  border: 0;
  border-top: 1px solid black;
  color: black;
  height: 0;
  margin: 1em auto;
  max-width: 80%;
  position: relative;
}
.poem {
  white-space: pre;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
header {
  text-align: center;
  background-color: #f2f2f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 1em;
}
header .container {
  margin: auto;
  padding: 0;
}
#headerID {
  font-family: Tangerine, cursive;
  font-size: 2.2222222222em;
  margin: 0;
  position: relative;
  font-weight: 400;
}
@media (min-width: 40em) {
  #headerID {
    font-size: 3.5555555556em;
  }
}
#headerID a {
  border: none;
}
#headerID .name {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 2px 10px black;
  color: #484848;
}
#headerID .title {
  pointer-events: none;
  font-size: 2em;
  display: block;
  color: rgba(34, 34, 34, 0.15);
  user-select: none;
}
footer {
  text-align: center;
  background-color: #f2f2f2;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.5);
}
footer p {
  margin: 0;
}
footer a {
  border-bottom: 0;
  color: #222;
}
footer .container {
  display: flex;
  justify-content: center;
}
footer .footer-links {
  display: grid;
  gap: 1em;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "facebook instagram" "copyright copyright";
}
@media only screen and (min-width: 30em) {
  footer .footer-links {
    grid-template-rows: auto;
    grid-template-columns: fit-content(2em) fit-content(15em) fit-content(2em);
    grid-template-areas: "facebook copyright instagram";
  }
}
footer .footer-links .facebook {
  grid-area: facebook;
}
footer .footer-links .facebook > * {
  margin-left: auto;
}
footer .footer-links .copyright {
  grid-area: copyright;
}
footer .footer-links .instagram {
  grid-area: instagram;
}
footer .footer-links a.social {
  display: block;
  width: 2em;
  height: 2em;
}
footer .footer-links a.social:hover, footer .footer-links a.social:active, footer .footer-links a.social:focus {
  border-bottom: none;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
}
footer .footer-links a.social img {
  max-height: 100%;
  max-width: 100%;
}
section figure {
  clear: both;
  margin: auto;
  display: grid;
  gap: 1em;
  grid-template: "top" "bottom";
}
section figure figcaption {
  grid-area: bottom;
  display: block;
  text-align: center;
}
section figure > p, section figure > img {
  grid-area: top;
}
@media only screen and (min-width: 40em) {
  section figure {
    grid-template: "left right";
  }
  section figure figcaption {
    grid-area: unset;
    display: block;
    text-align: center;
  }
  section figure > p, section figure > img {
    grid-area: unset;
  }
}
section .float-left {
  float: left;
  margin: 1em;
  margin-left: 0;
}
section .float-right {
  float: right;
  margin: 1em;
  margin-right: 0;
}
section .clear {
  clear: both;
}
section .clear-left {
  clear: left;
}
section .clear-right {
  clear: left;
}
section img {
  clear: both;
  box-shadow: 0 4px 15px black;
  max-width: 100%;
  display: block;
  margin: 1em auto;
}
section .frame {
  box-shadow: 0 4px 15px black;
  border-style: solid;
  border-color: #000;
  border-width: 1em;
  margin: 1em auto;
  max-width: 100%;
}
section .frame p {
  margin: 0;
  padding: 0;
}
section .frame img {
  box-shadow: none;
  border-style: solid;
  border-width: 2em;
  border-color: #ddd;
  margin: 0;
}
section .row {
  margin: 1em auto;
}
section .row p {
  margin: 0;
}
section .row > * {
  display: block;
}
section .row img,
section .row .frame {
  margin: 0 0.22em;
}
section .row .frame img {
  margin: 0;
}
section .row, section .row > p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 40em) {
  section .row.row-multi, section .row.row-multi > p {
    flex-direction: column;
  }
}
section .row.row-multi .frame {
  border-width: 0.5em;
}
section .row.row-multi .frame img {
  border-width: 1em;
}
section .row.row-multi img {
  max-width: initial;
  max-height: initial;
}
section .row.row-single .frame {
  border-width: 1em;
}
section .row.row-single .frame img {
  border-width: 2em;
}
section .row.row-single img {
  max-width: 100%;
}
nav {
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  font-weight: 200;
  text-align: center;
  font-size: 0.8888888889em;
}
nav a {
  border-bottom: 0;
}
nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
nav ul li {
  display: inline-block;
  font-size: 1em;
  padding: 0.5em 0;
  margin: 0;
  flex: 1;
}
nav li:not(:last-child):after {
  content: " | ";
  display: inline-block;
  padding: 0.5em;
}
.form-field {
  margin: 1em auto;
}
.form-label {
  display: block;
}
.form-label .required {
  opacity: 0.5;
}
textarea,
input[type=text],
input[type=number],
input[type=email] {
  background: #eee;
  border: 0;
  padding: 0.5em;
  font: inherit;
  display: block;
  width: 100%;
  margin: auto;
}
textarea:focus,
input[type=text]:focus,
input[type=number]:focus,
input[type=email]:focus {
  background: white;
}
textarea {
  height: 10em;
}
form .buttons {
  text-align: center;
}
.button {
  display: inline-block;
  border-radius: 0;
  border: 0;
  padding: 0.25em 0.5em;
  font-size: 1.25em;
  font: inherit;
  background: #eee;
}
.button:hover {
  background: white;
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  background-color: white;
  background-image: url("graybackgroundlight.3e06184b.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  color: #222;
  font-family: Cormorant, serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body > header {
  z-index: 2;
}
body > section {
  z-index: 1;
  flex: 1;
}
body > section .container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: white;
}
body > footer {
  z-index: 2;
}
a {
  text-decoration: none;
  color: black;
  border-bottom: 3px double rgba(0, 0, 0, 0.4);
}
a:hover, a:focus {
  border-bottom: 3px double rgba(0, 0, 0, 0.7);
  outline: none;
}
a:visited {
  color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.4);
}
.container {
  max-width: 40em;
  margin: auto;
  padding: 1em;
}
/*# sourceMappingURL=style.css.map */
