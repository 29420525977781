footer {
    text-align: center;
    background-color: $color-bg-footer;
    box-shadow: 0 -4px 8px $color-shadow;
    p {
        margin: 0;
    }
    a {
        border-bottom: 0;
        color: $color-link-footer;
    }

    .container {
        display: flex;
        justify-content: center;
    }

    .footer-links {
        display: grid;
        gap: 1em;

        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "facebook instagram" "copyright copyright";

        @media only screen and (min-width: 30em) {
            grid-template-rows: auto;
            grid-template-columns: fit-content(2em) fit-content(15em) fit-content(2em);
            grid-template-areas: "facebook copyright instagram";
        }

        .facebook {
            grid-area: facebook;
            > * {
                margin-left: auto;
            }
        }

        .copyright {
            grid-area: copyright;
        }

        .instagram {
            grid-area: instagram;
        }

        a.social {
            display: block;
            width: 2em;
            height: 2em;

            &:hover, &:active, &:focus {
                border-bottom: none;
                background: rgba(0,0,0, 0.15);
                border-radius: 0.25em;
            }

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}
