@use "sass:math";

$ratio: 9*0.125;

body {
    font-size: $size-font;
    line-height: 1.5;
}

h6 {
    font-size: $size-font;
}
h5 {
    font-size: $size-font;
    line-height: 1 + math.div((1*0.5), 1);
}
h4 {
    font-size: $size-font * $ratio;
    line-height: 1 + ((1*0.5) * 0.5);
    line-height: 1.3;
    font-variant: small-caps;
}
h3 {
    font-size: $size-font * $ratio * $ratio;
    line-height: 1 + math.div((1*0.5), 3);
    line-height: 1.2;
    font-variant: small-caps;
}
h2 {
    font-size: $size-font * $ratio * $ratio * $ratio;
    line-height: 1 + ((1*0.5) * 0.25);
    font-variant: small-caps;
}
h1 {
    font-size: $size-font * $ratio * $ratio * $ratio * $ratio;
    line-height: 1;    
}

blockquote {
    p {
        margin: 0;
    }
    color: $color-accent-quote;

    position: relative;

    &:before {
        float: left;
        width: 0.5em;
        height: 0.5em;
        position: relative;
        top: -0.2em;
        content: '“';
        font-size: 2em;
        opacity: 0.5em;
    }
    padding: 0 1em;
    line-height: 1;
    margin: 0;
    font-style: italic;
    cite {
        margin-top: 0.5em;
        font-style: normal;
        text-align: right;
        display: block;
        &:before {
            content: '—';
        }
    }
}

dl {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    font-size: 0.8em;

    dd, dt {
        margin: 0;
        padding: 0.0em 0;
    }

    dt {
        &:first-line {
            font-weight: bold;
        }
        flex: 1 0 50%;
        padding-right: 1em;
        border-right: 1px solid $color-accent-dark;
        text-align: right;
    }
    dd {
        position: relative;
        left: -1px;
        border-left: 1px solid $color-accent-dark;
        flex: 1 0 50%;
        padding-left: 1em;
        text-align: left;
    }
}

hr {
    border: 0;
    border-top: 1px solid $color-accent-dark;
    color: $color-accent-dark;
    height: 0;
    margin: 1em auto;
    max-width: 80%;
    position: relative;
}

.poem {
    white-space: pre;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}
