@import 'variables';
@import 'typography';
@import 'header';
@import 'footer';
@import 'images';
@import 'navs';
@import 'forms';

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    background-color: $color-bg-body;
    background-image: url('../images/graybackgroundlight.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    color: $color-fg-body;
    font-family: $font-body;
    margin: 0;
    padding: 0;
 
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    > header {
        z-index: 2;
    }
    > section {
        z-index: 1;
        flex: 1;
        .container {
            box-shadow: 0 4px 8px $color-shadow;
            background-color: $color-bg-body;
        }
    }
    > footer {
        z-index: 2;
    }
}

a {
    text-decoration: none;
    color: $color-fg-link;
    border-bottom: 3px double $color-underline-link;
    &:hover, &:focus {
        border-bottom: 3px double $color-underline-link-focused;
        outline: none;
    }
    &:visited {
        color: $color-fg-link-visited;
        border-bottom-color: $color-underline-link-visited;
    }
}

.container {
    max-width: $size-body;
    margin: auto;
    padding: 1em;
}
