@use "sass:math";

header {
    text-align: center;
    background-color: $color-bg-header;
    box-shadow: 0 4px 8px $color-shadow;
    .container {
        margin: auto;
        padding: 0;
    }
    padding: 1em;
}

#headerID {
    font-family: $font-heading;
    font-size: math.div(16, 18) * 2.5em;
    @media (min-width: 40em) {
        font-size: math.div(16, 18) * 4em;
    }
    margin: 0;
    position: relative;
    font-weight: 400;

    a {
        border: none;
    }

    .name {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-shadow: 0 2px 10px black;
        color: $color-header-name;
    }

    .title {
        pointer-events: none;
        font-size: 2em;
        display: block;
        color: $color-header-title;
        user-select: none;
    }
}
