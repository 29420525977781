section {

    figure {
        clear: both;
        margin: auto;

        display: grid;

        gap: 1em;
        grid-template: "top" "bottom";

        figcaption {
            grid-area: bottom;
            display: block;
            text-align: center;
        }

        > p, > img {
            grid-area: top;
        }

        @media only screen and (min-width: 40em) {
            grid-template: "left right";

            figcaption {
                grid-area: unset;
                display: block;
                text-align: center;
            }

            > p, > img {
                grid-area: unset;
            }
        }
    }
    
    .float-left {
        float: left;
        margin: 1em;
        margin-left: 0;
    }

    .float-right {
        float: right;
        margin: 1em;
        margin-right: 0;
    }

    .clear {
        clear: both;
    }

    .clear-left {
        clear: left;
    }

    .clear-right {
        clear: left;
    }

    img {
        clear: both;
        box-shadow: 0 4px 15px black;
        max-width: 100%;
        display: block;
        margin: 1em auto;
    }

    .frame {
        p {
            margin: 0;
            padding: 0;
        }
        box-shadow: 0 4px 15px black;
        border-style: solid;
        border-color: $color-img-frame-outer;
        border-width: 1em;
        margin: 1em auto;
        max-width: 100%;
        img {
            box-shadow: none;
            border-style: solid;
            border-width: 2em;
            border-color: $color-img-frame-inner;
            margin: 0;
        }
    }

    .row {
        margin: 1em auto;

        p {
            margin: 0;
        }

        > * {
            display: block;
        }

        img,
        .frame {
            margin: 0 0.22em;
        }

        .frame img {
            margin: 0;
        }

        &, & > p {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        &.row-multi,
        &.row-multi > p {
            @media (max-width: 40em) {
                flex-direction: column;
            }
        }

        &.row-multi {
            .frame {
                border-width: 0.5em;
                img {
                    border-width: 1em;
                }
            }

            img {
                max-width: initial;
                max-height: initial;
            }
        }

        &.row-single {
            .frame {
                border-width: 1em;
                img {
                    border-width: 2em;
                }
            }
            img {
                max-width: 100%;
            }
        }
    }
}
