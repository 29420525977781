.form-field {
    margin: 1em auto;
}

.form-label {
    display: block;
    .required {
        opacity: 0.5;
    }
}

textarea,
input[type=text],
input[type=number],
input[type=email] {
    background: $color-bg-input;
    &:focus {
        background: $color-bg-input-focused;
    }
    border: 0;
    padding: 0.5em;
    font: inherit;
    display: block;
    width: 100%;
    margin: auto;
}

textarea {
    height: 10em;
}

form {
    .buttons {
        text-align: center;
    }
}

.button {
    display: inline-block;
    border-radius: 0;
    border: 0;
    padding: 0.25em 0.5em;
    font-size: 1.25em;
    font: inherit;
    background: $color-bg-button;
    &:hover {
        background: $color-bg-button-focused;
    }
}
