@use "sass:math";

nav {
    font-family: $font-nav;
    text-transform: uppercase;
    font-weight: 200;
    text-align: center;
    font-size: math.div(16, 18) * 1em;
}

nav a {
    border-bottom: 0;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    display: inline-block;
    font-size: 1em;
    padding: 0.5em 0;
    margin: 0;
    flex: 1;
}

nav {
    li:not(:last-child):after {
        content: ' | ';
        display: inline-block;
        padding: 0.5em;
    }
}